<template>
  <div class="main-layout">
      <!-- loader  -->
      <!-- <div class="loader_bg">
         <div class="loader"><img src="/static/images/loading.gif" alt="#" /></div>
      </div> -->
      <!-- end loader -->
      <!-- header -->
      <header>
         <!-- header inner -->
         <div  class="head_top">
            <div class="header">
               <div class="container-fluid">
                  <div class="row">
                     <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col logo_section">
                        <div class="full">
                           <div class="center-desk">
                              <div class="logo">
                                 <figure><img src="/static/images/alberta.svg" alt="#"/></figure>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <!-- end header inner -->
            <!-- end header -->
            <!-- banner -->
            <section class="banner_main">
               <div class="container-fluid">
                  <div class="row d_flex">
                     <div class="col-md-6">
                        <div class="text-bg">
                           <h1>Digital Marketing</h1>
                           <p>Digital marketing also known as content marketing is a strategic approach focused on creating and distributing valuable, relevant, and consistent content to attract and retain a clearly defined audience. It ultimately aims to drive profitable customer action.
                           <br/>Urbans is a digital marketing service offered by us.</p>
                        </div>
                     </div>
                     <div class="col-md-6">
                        <div class="text-img">
                           <figure><img src="/static/images/box_img.png" alt="#"/></figure>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </div>
      </header>
      <!-- end banner -->
      <!-- business -->
      <div class="business">
         <div class="container">
            <div class="row">
               <div class="col-md-12">
                  <div class="titlepage">
                     <span>Increase your client focus</span>
                     <h2>Better position of Business</h2>
                     <p>Take advantage of what you know about your customers to create and deliver content that compels them to action.</p>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-md-10 offset-md-1">
                  <div class="row">
                     <div class="col-md-12">
                        <div class="business_box ">
                           <figure><img src="/static/images/business_img.jpg" alt="#"/></figure>
                           <p>Website visitors are seeking credible, authoritative information that provides assurance of an individual or company, and they’re looking for something that they can relate to. A brand personality they can trust, an accurate representation of what you can offer, and a willingness to prove that expertise without asking for an immediate buy-in. By establishing your credibility time and time again, you become the place your target customers go to for information they can trust. And when you’re their source for knowledge, you can bet you’ll be the first place they turn when they’re in need of your services or products, too.</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <!-- end business -->
      <!-- Projects -->
      <div class="projects">
         <div class="container">
            <div class="row">
               <div class="col-md-12">
                  <div class="titlepage">
                     <span>Previous Projects</span>
                     <h2>VHaul Movers</h2>
                     <p>VHaul Movers has been serving Canada since 2009. They help people move locally, and across the country.</p>
                  </div>
               </div>
            </div>
            <div class="row">
               <div class="col-md-10 offset-md-1">
                  <div class="row">
                     <div class="col-md-6 offset-md-3">
                        <div class="projects_box ">
                           <figure><img src="/static/images/projects_img.png" alt="#"/></figure>
                        </div>
                     </div>
                     <div class="col-md-12">
                        <div class="projects_box ">
                           <p>We have created a website for them which would offer moving services 24/7 throughout the year. With our services they are able to get clients on one click away for moving day organized and easy. We helped with user experience to ease moving with VHaul Movers.</p>
                           <a class="read_more" href="https://vhaulmovers.ca/" target="_blank">Read more</a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      <!-- end projects -->
      <!-- Testimonial -->
      <div class="section">
         <div class="container">
            <div id="" class="Testimonial">
               <div class="row">
                  <div class="col-md-12">
                     <div class="titlepage">
                        <h2>Testimonial</h2>
                     </div>
                  </div>
               </div>
               <div class="row">
                  <div class="col-md-3">
                     <div class="Testimonial_box">
                        <i><img src="/static/images/plan1.png" alt="#"/></i>
                     </div>
                  </div>
                  <div class="col-md-9">
                     <div class="Testimonial_box">
                        <h4>Crystal Turbuck</h4>
                        <p>Thanks for the support and understanding the critical of going live in time on this project. Our clients have appreciated the work done by your team.
                        </p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
     
      <!-- end Testimonial -->
      <!-- contact -->
      <div id="contact" class="contact">
         <div class="container">
            <div class="row">
               <div class="col-md-12">
                  <div class="titlepage">
                     <h2>Contact us</h2>
                     <!-- <span>There are many variations of passages of Lorem Ipsum available, but the </span> -->
                  </div>
               </div>
            </div>
         </div>
         <div class="container">
            <div class="row">
               <div class="col-md-12 ">
                  <form class="main_form " @submit="displayAlert">
                     <div class="row">
                        <div class="col-md-12 ">
                           <input class="form_contril" placeholder="Name " type="text" name="Name " v-model="user.name" required>
                        </div>
                        <div class="col-md-12">
                           <input class="form_contril" placeholder="Phone Number" type="tel" name=" Phone Number" v-model="user.phone" required>
                        </div>
                        <div class="col-md-12">
                           <input class="form_contril" placeholder="Email" type="email" name="Email" v-model="user.email" required>
                        </div>
                        <div class="col-md-12">
                           <textarea class="textarea" placeholder="Message" type="text" name="Message" v-model="user.message" required></textarea>
                        </div>
                        <div class="col-sm-12">
                           <button class="send_btn">Send</button>
                        </div>
                     </div>
                  </form>
               </div>
            </div>
         </div>
      </div>
      <!-- end contact -->
      <!--  footer -->
      <footer>
         <div class="footer">
            <!-- <div class="container">
               <div class="row">
                  <div class="col-md-12">
                     <ul class="social_icon">
                        <li><a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a></li>
                        <li><a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a></li>
                        <li><a href="#"><i class="fa fa-linkedin" aria-hidden="true"></i></a></li>
                     </ul>
                  </div>
               </div>
            </div> -->
            <div class="copyright">
               <div class="container">
                  <div class="row">
                     <div class="col-md-12">
                        <p> ©️ Alberta Corporation 2371231</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </footer>
      <!-- end footer -->
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
    return {
      user: {
        name: '',
        phone: '',
        email: '',
        message: '',
      }
    };
  },
  methods: {
    displayAlert() {
      alert("We have taken your request. Our team will contact you soon!");
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
